<template>
  <div class="mainform">
    <div class="mainHeader">
      <span v-if="livraisonCode">送货单详情</span>
      <span v-else>创建送货单</span>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form">
      <el-form class="content" :class="{disabled:formDisabled}" ref="form" :model="form" >
        <div class="elrowflx">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">送货基地</div>
              <div class="xjcont">{{ dataBox.baseName }}</div>
            </el-col>
            <el-col v-if="livraisonCode" class="clearfix" :span="12">
              <div class="xjtit">送货单号</div>
              <div class="xjcont">{{ form.bidScoringList[0].livraisonCode }}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">基地地址</div>
              <div class="xjcont">{{ dataBox.baseAddr }}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">预计送达日期</div>
              <div class="xjcont" v-if="livraisonCode">{{ form.bidScoringList[0].expectTime }}</div>
              <div class="xjcont" v-else>
                <el-form-item label-width="0">
                  <el-date-picker v-model="dataBox.expectTime" type="date" placeholder="请选择"></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">基地联系人</div>
              <div class="xjcont">{{ dataBox.baseHead }} {{ dataBox.phone }}</div>
            </el-col>
            <el-col v-if="livraisonCode" class="clearfix" :span="12">
              <div class="xjtit">状态</div>
              <div class="xjcont">{{form.bidScoringList[0].status=='0'?"未发货":form.bidScoringList[0].status=='1'?"已发货":form.bidScoringList[0].status=='2'?"已作废":""}}</div>
            </el-col>
            <!-- <el-col v-if="livraisonCode" class="clearfix" :span="12">
              <div class="xjtit">内向送货单号</div>
              <div class="xjcont">{{ form.bidScoringList[0].reverseOrderCode }}</div>
            </el-col> -->
          </el-row>
        </div>
        <div class="excel-tab">
          <el-table size="small" :data="form.bidScoringList" stripe style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="materialCode" label="物料编号" width="90px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="materialName" label="物料描述" width="220px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="demandNumber" label="需求数量" width="90px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="livraisonNumber" label="已发布/已发货数量" width="150px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="reverseOrderCode" label="内向送货单号" width="130px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="orderCode" class="orderDataList" label="采购单编号" width="180px" :show-overflow-tooltip="true">
              <template #default="scope" v-if="!livraisonCode">
                <el-button type="text" @click="purchaseNum(scope.row, scope.$index)">
                  {{ scope.row.orderDataList ? scope.row.orderDataList[0].orderRowCode : '选择订单'}}
                </el-button>
                <el-tooltip class="item" effect="dark" placement="top">
                  <template #content>
                    <div class="tkBox">
                      <el-table :data="scope.row.orderDataList" border style="width: 100%">
                        <el-table-column prop="orderRowCode" label="采购单编号/行号" width="180"></el-table-column>
                        <el-table-column prop="number" label="送货数量" width="100"></el-table-column>
                        <el-table-column prop="companyCode" label="工厂" width="80"></el-table-column>
                      </el-table>
                    </div>
                  </template>
                  <el-button type="text" icon='el-icon-warning-outline'></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="orderRow" label="采购单行号" width="100px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="xsdBh" label="销售单编号" width="140px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="shNumber" v-if="!livraisonCode" label="送货数量" width="100px"></el-table-column>

            <el-table-column prop="entrepotData" v-if="!livraisonCode" label="出仓库位" width="120px" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-select
                v-model="scope.row.entrepotData"
                filterable
                remote
                reserve-keyword
                value-key="id"
                placeholder="库位"
                @focus="clearData(scope.row)"
                :remote-method="querySearch">
                  <el-option
                    v-for='item in newSelect'
                    :key='item.id'
                    :label='item.lable'
                    :value='item'>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="demandCode" label="送货需求单号" width="150px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="demandRow" label="需求单行号" width="90px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column v-if="livraisonCode" class-name="yjsdrq" prop="sdDate" label="预计送达日期" width="120px" :show-overflow-tooltip="true">
              <template #default="scope">
                <div v-if="scope.row.editsymbol"><el-date-picker v-model="scope.row.updateData" type="date" placeholder="请选择"></el-date-picker></div>
                <div v-if="!scope.row.updateData&&!scope.row.editsymbol">{{form.bidScoringList[0].expectTime}}</div>
                <div v-if="scope.row.updateData&&!scope.row.editsymbol">{{scope.row.updateData}}</div>
              </template>
            </el-table-column>

            <el-table-column v-if="livraisonCode" label="操作" class-name="actionClo" min-width="170px" fixed="right">
              <template #default="scope">
                <el-button v-if="!scope.row.editsymbol && scope.row.reverseOrderCode && scope.row.reverseOrderCode !=''" size="small" type="text" @click="editDate(scope)">编辑</el-button>
                <el-button v-if="scope.row.editsymbol" size="small" type="text" @click="editDateSave(scope)">保存</el-button>
                <el-button v-if="scope.row.editsymbol" size="small" type="text" @click="editDateCancel(scope)">取消</el-button>
                <el-button v-if="(scope.row.reverseOrderCode??'')!=''" size="small" type="text" @click="downloadAsn(scope.row)" >下载ASN</el-button>
                <el-button v-if="(scope.row.reverseOrderCode??'')!=''" size="small" type="text" @click="uploadAsn(scope.row)">上传SN</el-button>
                <el-button v-if="(scope.row.fileInfo??'')!=''&&(scope.row.fileInfo.url??'')!=''" size="small" type="text" @click="downloadSn(scope.row)" >下载SN</el-button>
                <el-button v-if="(scope.row.fileInfo??'')!=''&&(scope.row.fileInfo.url??'')!=''" size="small" type="text" @click="deleteSn(scope.row)" >删除SN</el-button>
              </template>
            </el-table-column>

            <el-table-column v-if="!livraisonCode" label="操作" width="60px" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-button size="small" type="text" @click="deleteRow(scope.$index, form.bidScoringList)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="action" v-if="!livraisonCode">
          <el-button class="submit" type="primary" size="medium" @click="preservation">发布</el-button>
        </div>
      </el-form>
      <div class="action" v-if="livraisonCode">
        <div v-if="form.bidScoringList[0].status!==2">
          <el-button v-if="form.bidScoringList[0].status !== 2" class="submit" type="primary" size="medium" @click="edist">作废</el-button>
          <el-button v-if="form.bidScoringList[0].status === 1" class="submit" type="primary" size="medium" @click="Printing">打印送货单</el-button>
          <el-button v-if="form.bidScoringList[0].status === 1" class="submit" type="primary" size="medium" @click="download">下载</el-button>
          <el-button v-if="form.bidScoringList[0].status!==1" class="submit" type="primary" size="medium" @click="deliverGoods">发货</el-button>
        </div>
      </div>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="1046px" :dialogShow="dialogShow" :sellCodeStart="sellCodeStart" @handleClose='handleClose' title="选择订单" componentName="AddPurchaseNumBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action" :dialogData="dialogData"></dialog-box>

    <!-- 点击作废按钮弹框 -->
    <div class="dialogBox zfbox">
      <el-dialog top="0px" title="作废" v-model="dialogModify1" width="520px">
        <div class="content">
          <div class="mainform">
            <div class="form">
              <el-form :model="explain" label-width="0" class="demo-ruleForm">
                <div class="zfsm" v-if="dataBox.baseCode === '8000'">
                  是否要作废该送货单，如果确认作废，请填写作废原因并联系采购员在SAP同步删除
                </div>
                <div class="zfsm" v-if="dataBox.baseCode !== '8000'">
                  请填写作废原因
                </div>
                <el-form-item label="" prop="elpCont">
                  <el-input type="textarea" v-model="explain.elpCont" placeholder="请填写"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button size="mini" @click="dialogModify1 = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="toVoid">确 定</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { bidSupplierList } from './js/setting.js'
import { request, exportForm, baseURL, requesFile } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'AddSupplierDemand',
  data: function () {
    return {
      id: this.$route.query.id,
      livraisonCode: this.$route.query.livraisonCode,
      receiveData: [],
      dialogModify1: false,
      formDisabled: false,
      dialogShow: false,
      dialogData: [],
      sellCodeStart: '',
      dataBox: {},
      kwData: {},
      supFlagData: {},
      menudata: this.$store.state.ViewMenuData.buttonData,
      explain: {
        elpCont: ''
      },
      form: {
        shTime: '',
        businessScope: '',
        formData: bidSupplierList,
        bidScoringList: [{}]
      },
      newSelect: [],
      newOneData: []
    }
  },
  mounted () {
    if (this.livraisonCode) {
      this.formDisabled = true
    }
  },
  created () {
    this.receiveData = this.$store.state.PageValue
    this.getIdData()
  },
  methods: {
    downloadAsn (data) {
      exportForm('/api/livraison/livrasion/downAsn', 'post', [data], true).then((response) => {
        console.log(response)
        // const aBlob = new Blob([response.data])
        this.downloadByBlob(response.data)
      })
    },
    downloadSn (data) {
      window.open(data.fileInfo.url)
    },
    deleteSn (data) {
      this.$confirm('是否删除？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/livraison/livrasion/delAsn', 'POST', data).then((res) => {
          if (res.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getIdData()
          }
        })
      }).catch(() => {
        this.$message({
          message: '操作取消',
          type: 'info'
        })
      })
    },
    uploadAsn (data) {
      try {
        const input = document.createElement('input')
        input.type = 'file'
        input.click()
        input.onchange = () => {
          const formData = new FormData()
          formData.append('file', input.files[0])
          requesFile(baseURL + '/api/file/m/file/uploadToServer', 'post', formData).then((response) => {
            if (response.code === '200') {
              data.fileInfo = response.data[0]
              request('/api/livraison/livrasion/uploadAsn', 'post', data).then((res) => {
                console.log(res)
                if (res.code === '200') {
                } else {
                  data.fileInfo = {}
                }
              })
            }
            input.remove()
          })
        }
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    editDate (scope) {
      if (!scope.row.updateData) {
        scope.row.oldDate = this.form.bidScoringList[0].expectTime
        scope.row.updateData = this.form.bidScoringList[0].expectTime
      } else {
        scope.row.oldDate = scope.row.updateData
      }
      scope.row.editsymbol = true
    },
    editDateCancel (scope) {
      scope.row.updateData = scope.row.oldDate
      scope.row.editsymbol = false
    },
    editDateSave (scope) {
      scope.row.updateData = dayjs(scope.row.updateData).format('YYYY-MM-DD')
      if (((!scope.row.updateData && ((new Date(this.form.bidScoringList[0].expectTime).getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()) / (1000 * 60 * 60 * 24)) <= 3 &&
      ((new Date(this.form.bidScoringList[0].expectTime).getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()) / (1000 * 60 * 60 * 24)) > 0) ||
      (scope.row.updateData && ((new Date(scope.row.oldDate).getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()) / (1000 * 60 * 60 * 24)) <= 3 &&
      ((new Date(scope.row.oldDate).getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()) / (1000 * 60 * 60 * 24)) > 0))
      ) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '计划到货日期在3天之内，不允许修改!'
        })
        return false
      }
      if ((scope.row.updateData && ((new Date(scope.row.updateData).getTime() - new Date(sessionStorage.getItem('serversDate')).getTime()) / (1000 * 60 * 60 * 24)) > 12)) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '预计送达日期必须在12天以内，请核查！'
        })
        return false
      }
      const obj = {
        reverseOrderCode: scope.row.reverseOrderCode,
        oldTime: scope.row.oldDate,
        newTime: scope.row.updateData,
        companyCode: scope.row.companyCode
      }
      request('/api/livraison/livrasion/getSendOrderBySap/' + scope.row.id, 'get', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          scope.row.editsymbol = false
        }
      })
    },
    // 根据单号请求数据
    getIdData () {
      if (!this.livraisonCode) {
        // 创建送货单请求
        var newDemand = this.receiveData
        var arrList = []
        newDemand.forEach((item) => {
          arrList.push({
            demandCode: item.demandCode,
            demandRow: item.demandRow
          })
        })
        const obj = [...arrList]
        if (arrList.length <= 0) {
          this.$router.push({ path: '/InventoryDelivery/SupplierDemand' })
          return false
        }
        request('/api/livraison/livrasion/getLivraDemandListByCodes', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dataBox = res.data.baseInfo
            this.form.bidScoringList = res.data.livraInfo
            this.form.bidScoringList.forEach(item => {
              item.shNumber = 0
            })
          }
        })
      } else {
        // 查看送货单详情
        request('/api/livraison/livrasion/getLivraisonDetialDataByLivraiCode?' + 'livraisonCode=' + this.livraisonCode, 'POST').then((res) => {
          if (res.code === '200') {
            if (res.data.baseInfo) {
              this.dataBox = res.data.baseInfo
            }
            this.form.bidScoringList = res.data.demandDetailInfo
            this.supFlagData = res.data.flagData
          }
        })
      }
    },
    // 选择订单编号、行号进行编辑
    purchaseNum (row, index) {
      this.sellCodeStart = 2

      request('/api/livraison/livrasion/getOrderBySap', 'get', { code: row.demandCode, row: row.demandRow, companyCode: row.companyCode }).then((res) => {
        if (res.code === '200') {
          console.log(res)
          const ddlist = []
          for (const val of res.data) {
            const temp = {}
            temp.remainNumber = val.DABMG
            temp.orderRowCode = val.EBELN + '-' + val.EBELP
            temp.demandDate = val.EINDT
            temp.MATNR = val.MATNR
            temp.MENGE = val.MENGE
            temp.BSTRF = val.BSTRF

            temp.ETENR = val.ETENR
            temp.SRMID = val.SRMID

            temp.BEDNR = val.BEDNR
            temp.ZTEXT = val.ZTEXT

            temp.sellCode = val.VBELN
            temp.sellRow = val.VBELP
            temp.companyCode = val.WERKS
            temp.ZSHIT = val.ZSHIT
            temp.ZSHXQ = val.ZSHXQ
            temp.isLock = val.isLock
            temp.number = val.XQMENGE > 0 ? val.XQMENGE : ''
            temp.demandType = row.demandType
            ddlist.push(temp)
          }
          const orderData = { orderData: ddlist }
          orderData.demandNumber = row.demandNumber
          orderData.remainNumber = row.remainNumber
          this.newOneData = row
          this.dialogData = orderData
          this.dialogShow = true
        }
      })
    },
    // 点击采购单编号、行号弹窗里的确定按钮
    action () {
      var newListData = this.$refs.queryDialog.$refs.content.newSelectTabData
      console.log(newListData)
      if (newListData.length > 0) {
        var DeliveryNum = ''
        var UnpaidNum = ''
        var newTwo = true
        newListData.forEach(item => {
          item.XQMENGE = item.number
          DeliveryNum = Number(item.number)
          UnpaidNum = item.remainNumber
          if (DeliveryNum > UnpaidNum || DeliveryNum === 0 || item.number === undefined) {
            newTwo = false
          }
        })
        if (!newTwo) {
          this.$message({
            showClose: true,
            message: '送货数量不能为空，且不能大于未交数量',
            type: 'warning'
          })
        } else {
          // if (newListData[0].demandType === 8 || newListData[0].demandType === '8' || newListData[0].demandType === 3 || newListData[0].demandType === '3') {
          request('/api/livraison/livrasion/checkIsCommit/' + this.dialogData.remainNumber, 'POST', newListData).then((res) => {
            if (res.code === '200') {
              this.dialogShow = false
              this.newOneData.orderDataList = newListData
              let newNum = 0
              this.newOneData.orderDataList.forEach(item => {
                newNum += Number(item.number)
              })
              this.newOneData.shNumber = newNum
            }
          })
          /* } else {
            this.dialogShow = false
            this.newOneData.orderDataList = newListData
            let newNum = 0
            this.newOneData.orderDataList.forEach(item => {
              newNum += Number(item.number)
            })
            this.newOneData.shNumber = newNum
          } */
        }
      } else {
        this.$message({
          showClose: true,
          message: '请选择订单',
          type: 'warning'
        })
      }
    },

    // 表格里的选择库位输入后匹配内容
    querySearch (queryString) {
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          this.kwData = item
          newListEnt.push({
            lable: item.entrPosiName,
            id: item.id
          })
        })
        this.newSelect = newListEnt
      })
    },
    // 判断库位输入库是否有值
    clearData (row) {
      if (!row.entrepotData) {
        this.newSelect = []
      }
    },
    // 点击详情页作废按钮弹框
    edist () {
      this.dialogModify1 = true
    },
    // 点击详情页作废按弹框里的确认按钮
    toVoid () {
      if (this.explain.elpCont) {
        const obj = {
          livraisonCode: this.form.bidScoringList[0].livraisonCode,
          invalidState: this.explain.elpCont
        }
        request('/api/livraison/livrasion/invalidDemandDetailStatusBySHDCode', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dialogModify1 = false
            this.$router.push({
              path: '/InventoryDelivery/SupplierSingler'
            })
            this.$message({
              showClose: true,
              type: 'success',
              message: '操作成功'
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请填写作废原因'
        })
      }
    },
    // 发货弹框
    deliverGoods (data) {
      this.$confirm('确认要发货吗？', '发货', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deliverFo(data)
      })
    },
    // 请求发货
    deliverFo () {
      request('/api/livraison/livrasion/updateDemandDetailStatusByCode?' + 'livraisonCode=' + this.livraisonCode, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.getIdData()
        }
      })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        /* if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        } */
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 发布
    preservation () {
      if (!this.dataBox.expectTime) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择预计送达时间，时间需大于等于3天并小于等于12天！'
        })
        return
      }

      const ct = (this.dataBox.expectTime.getTime() - new Date(window.sessionStorage.getItem('serversDate').split(' ')[0] + ' 00:00:00').getTime()) / (1000 * 60 * 60 * 24)

      if (ct > 12 || ct < 3) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '预计送达时间需大于等于3天并小于等于12天，请核查'
        })
        return
      }

      var newtypr = true
      // this.form.bidScoringList.forEach(r => {
      //   if (!r.entrepotData) {
      //     newtypr = false
      //   }
      // })
      this.form.bidScoringList.forEach(r => {
        if (!r.orderDataList) {
          newtypr = false
        }
      })
      if (newtypr) {
        let rkksTime = ''
        const oneData = []
        this.form.bidScoringList.forEach(item => {
          item.orderDataList.forEach(a => {
            a.number = Number(a.number)
          })
          oneData.push({
            demandCode: item.demandCode,
            demandRow: item.demandRow,
            entrepotInfoEntity: this.kwData,
            orderInfo: item.orderDataList,
            // entrepotId: item.entrepotData.id,
            // entrepotName: item.entrepotData.lable,
            totalNumber: item.shNumber
          })
        })
        oneData.forEach(a => {
          a.orderInfo.forEach(s => {
            s.orderData = s.orderRowCode
          })
        })
        if (this.dataBox.expectTime) {
          rkksTime = dayjs(this.dataBox.expectTime).format('YYYY-MM-DD')
        }
        const obj = {
          demandDetailUDataDtos: [
            ...oneData
          ],
          expectTime: rkksTime
        }
        request('/api/livraison/livrasion/createLivrasionDetail', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '操作成功'
            })
            this.$router.push({
              path: '/InventoryDelivery/SupplierSingler'
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请完善订单列表信息'
        })
      }
    },
    // 打印
    Printing () {
      if (this.supFlagData.asnFlag === 1 && this.supFlagData.sourceFlag === 0) {
        exportForm('/api/livraison/livrasion/printDemandDetail?livraisonCode=' + this.livraisonCode, 'GET').then((res) => {
          try {
            const url = window.URL.createObjectURL(res.data)
            const eleLink = document.createElement('a')
            eleLink.href = url
            eleLink.target = '_blank'
            eleLink.click()
            window.URL.revokeObjectURL(url)
          } catch (error) {
            console.error('download function error!', error)
          }
        })
      } else if (this.supFlagData.asnFlag === 0 && this.supFlagData.sourceFlag === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单未创建ASN，不允许打印，请稍后再试！'
        })
      } else if (this.supFlagData.asnFlag === 1 && this.supFlagData.sourceFlag === 1) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单为SAP创建，不允许打印！'
        })
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单未创建ASN或为SAP创建，不允许打印！'
        })
      }
    },
    // 下载
    download () {
      if (this.supFlagData.asnFlag === 1 && this.supFlagData.sourceFlag === 0) {
        exportForm('/api/livraison/livrasion/printDemandDetail?livraisonCode=' + this.livraisonCode, 'GET').then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.target = '_blank'
          eleLink.download = '送货单.pdf'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        })
      } else if (this.supFlagData.asnFlag === 0 && this.supFlagData.sourceFlag === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单未创建ASN，不允许下载，请稍后再试！'
        })
      } else if (this.supFlagData.asnFlag === 1 && this.supFlagData.sourceFlag === 1) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单为SAP创建，不允许下载！'
        })
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '该送货单未创建ASN或为SAP创建，不允许下载！'
        })
      }
      // var lineNum = this.lineNum
      //  如果有lineNum,就把lineNum和orderNum都传,没有就不传lineNum
    },
    // 删除一条数据
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
    // back () {
    //   this.$router.push({ path: '/InventoryDelivery/SupplierDemand' })
    // }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/dialogBox.scss';
  .zfbox{
    .form{
      padding: 0 20px;
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .zfsm{
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .bidding{
    position: relative;
  }
  .bidding-ipn{
    position: absolute;
    left: 205px;
    top: -1px;
    width: 160px;
  }
  .yqgys .content{
    padding: 0 !important;
  }
  .excel-tab{
    margin-bottom: 20px;
  }
  .btnlaft{
    float: left;
  }
  .mg-btnlaft{
    margin-right: 10px;
  }
  .gysname{
    margin-bottom: 20px;
    font-size: 14px;
    color: #666;
  }
  .gysspan{
    display: inline-block;
    padding-right: 10px;
  }
  .tkBox{
    .el-table td, .el-table th{
      padding: 4px 0;
    }
    :deep(.el-table--enable-row-transition .el-table__body td){
      padding: 4px 0;
    }
  }
  .mg-form{
    margin-top: 20px;
    :deep(.el-table th){
      padding: 9px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      padding: 2px 0;
      font-size: 14px;
    }
    :deep(.el-input__inner){
      height: 30px;
      line-height: 30px;
    }
    :deep(.el-input__icon) {
      line-height: 30px !important;
    }
    :deep(.el-input__suffix-inner){
      line-height: 0;
      vertical-align: top;
    }
    .elrowflx{
      margin-bottom: 20px;
      :deep(.el-col){
        line-height: 34px;
      }
    }
    .xjtit{
      float: left;
      margin-right: 15px;
      min-width: 90px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
      line-height: 20px;
      margin-top: 7px;
      margin-bottom: 5px;
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .disabled{
      :deep(.el-table td){
        padding: 9px 0;
      }
      :deep(.el-input__prefix){
        display: none;
      }
      :deep(.el-input__suffix){
        display: none;
      }
      :deep(.el-input__inner){
        padding: 0;
      }
      :deep(.el-input__inner){
        color: #666;
        font-size: 14px;
        background: none;
        border: 0;
      }
    }
  }
  .el-popper.is-dark{
    padding: 3px;
    background: #9e9e9e;
  }
  :deep(.el-popper__arrow::before){
    background: #9e9e9e !important;
  }

  :deep(.actionClo){
    .cell{
      line-height: 17px;
      .el-button--small{
        padding: 0px 0px;
        min-height: 14px;
      }
    }
  }
  .yjsdrq{
    :deep(.el-date-editor){
      width: auto;
      .el-input__inner{
        border: 1px solid #DCDFE6;
        padding-left: 8px;
      }
    }
  }
</style>
